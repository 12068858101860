import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './app/store';
import * as serviceWorker from './serviceWorker';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { TourProvider } from '@reactour/tour';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import Logout from './routes/Auth/Logout';
import PrivateRoute from './components/common/PrivateRoute';
import ErrorBoundary from './components/common/ErrorBoundry';
import ScrollToTop from './components/common/ScrollToTop';
import PageLoading from './components/common/PageLoading';
import lazyRetry from './components/common/lazyRetry';
import LanguageProvider from './LanguageProvider';
import VerifyAccount from './routes/Auth/VerifyAccount';

import './index.css';
import './App.css';
import VerifyPayment from './routes/Auth/VerifyPayment';
import AccountFailure from './routes/Auth/AccountFailure';
import VersionControl from './VersionControl';

const App = lazyRetry(() => import('./App'));
const Login = lazyRetry(() => import('./routes/Auth/Login'));
const Register = lazyRetry(() => import('./routes/Auth/Register'));
const ForgotPassword = lazyRetry(() => import('./routes/Auth/ForgotPassword'));
const ResetPassword = lazyRetry(() => import('./routes/Auth/ResetPassword'));
const LevelUpPassword = lazyRetry(() => import('./routes/Auth/LevelUpPassword'));
const PasswordlessLogin = lazyRetry(() => import('./routes/Auth/PasswordlessLogin'));

// function ContentComponent(props: any) {
//   const isLastStep = props.currentStep === props.steps.length - 1
//   const content = props.steps[props.currentStep].content
//   return (
//     <div style={{ border: '5px solid red', padding: 10, background: 'white' }}>
//       {/* Check if the step.content is a function or a string */}
//       {typeof content === 'function'
//         ? content({ ...props, someOtherStuff: 'Custom Text' })
//         : content}
//       <button
//         onClick={() => {
//           if (isLastStep) {
//             props.setIsOpen(false)
//           } else {
//             props.setCurrentStep((s: number) => s + 1)
//           }
//         }}
//       >
//         {isLastStep ? 'x' : '>'}
//       </button>
//     </div>
//   )
// }

const steps = [
  {
    selector: '[data-tour="step-1"]',
    content: "Welcome to lumberjack dashboard! Let's explore dashboard together!",
  },
  {
    selector: '[data-tour="step-2"]',
    content: 'Navigation menu will guide you through different sections of the dashboard',
  },
  {
    selector: '[data-tour="step-3"]',
    content: 'You can start creating short links with specific feature all in one place',
  },
  {
    selector: '[data-tour="step-4"]',
    content:
      'Advanced feature are short link creation utils with specific feature like QR code,Call to action,... You can choose what type of short link are you going to create',
  },
  {
    selector: '[data-tour="step-5"]',
    content: 'Industrially can help you to create many short link at once using CSV file or per-line',
  },
  // {
  //   selector: '[data-tour="step-6"]',
  //   content: 'Are you a developer? You can use apis to integrate with your own system!',
  // },
  {
    selector: '[data-tour="step-8"]',
    content: 'Manage your profile,billing and more using setting section',
  },
  {
    selector: '[data-tour="step-9"]',
    content: 'Having some feedback? try support',
  },
  {
    selector: '[data-tour="step-10"]',
    content: 'This section will show your monthly usage limit base on your selected plan and subscription',
  },
  {
    selector: '[data-tour="step-11"]',
    content: 'Usually syncing short links stats with dashboard takes few minutes',
  },
  {
    selector: '[data-tour="step-12"]',
    content: 'Showing total generated links',
  },
  {
    selector: '[data-tour="step-13"]',
    content: 'Brief summery of total visits of your short links',
  },
  {
    selector: '[data-tour="step-14"]',
    content: 'Short link list view,editing,deleting,disabling and compare are provided in this section',
  },
];

const themes = {
  dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/light-theme.css`,
};

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <VersionControl>
            <I18nextProvider i18n={i18n}>
              <ThemeSwitcherProvider themeMap={themes} defaultTheme={store.getState().ui.theme}>
                <LanguageProvider>
                  <BrowserRouter>
                    {navigator.userAgent.toUpperCase().indexOf('TRIDENT/') !== -1 ||
                    navigator.userAgent.toUpperCase().indexOf('MSIE') !== -1 ? (
                      <h4>Browser is not supported. Please use latest version of chrome,firefox,opera,... browsers</h4>
                    ) : (
                      <ScrollToTop>
                        <Routes>
                          <Route
                            path="/*"
                            element={
                              <PrivateRoute>
                                <React.Suspense fallback={<PageLoading />}>
                                  <TourProvider
                                    steps={steps}
                                    scrollSmooth={true}
                                    beforeClose={() => {
                                      if (!window.confirm('Show tour on next visit?')) {
                                        localStorage.setItem('_lum_tour', 'false');
                                      }
                                    }}
                                    // ContentComponent={ContentComponent}
                                    // styles={{ popover: (base) => ({ ...base, padding: 0 }) }}
                                  >
                                    <App />
                                  </TourProvider>
                                </React.Suspense>
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path="login"
                            element={
                              <React.Suspense fallback={<PageLoading />}>
                                <Login />
                              </React.Suspense>
                            }
                          />
                          <Route
                            path="register"
                            element={
                              <React.Suspense fallback={<PageLoading />}>
                                <Register />
                              </React.Suspense>
                            }
                          />
                          <Route
                            path="reset-password"
                            element={
                              <React.Suspense fallback={<PageLoading />}>
                                <ResetPassword />
                              </React.Suspense>
                            }
                          />
                          <Route
                            path="level-up-password"
                            element={
                              <React.Suspense fallback={<PageLoading />}>
                                <LevelUpPassword />
                              </React.Suspense>
                            }
                          />
                          <Route
                            path="forgot-password"
                            element={
                              <React.Suspense fallback={<PageLoading />}>
                                <ForgotPassword />
                              </React.Suspense>
                            }
                          />
                          <Route
                            path="passwordless-login"
                            element={
                              <React.Suspense fallback={<PageLoading />}>
                                <PasswordlessLogin />
                              </React.Suspense>
                            }
                          />
                          <Route
                            path="verify-account"
                            element={
                              <React.Suspense fallback={<PageLoading />}>
                                <VerifyAccount />
                              </React.Suspense>
                            }
                          />
                          <Route
                            path="account-subscription"
                            element={
                              <React.Suspense fallback={<PageLoading />}>
                                <VerifyPayment />
                              </React.Suspense>
                            }
                          />
                          <Route
                            path="account-failure"
                            element={
                              <React.Suspense fallback={<PageLoading />}>
                                <AccountFailure />
                              </React.Suspense>
                            }
                          />
                          <Route path="logout" element={<Logout />} />
                          <Route
                            path="*"
                            element={
                              <main style={{ padding: '1rem' }}>
                                <p>There's nothing here!</p>
                              </main>
                            }
                          />
                        </Routes>
                      </ScrollToTop>
                    )}
                  </BrowserRouter>
                </LanguageProvider>
              </ThemeSwitcherProvider>
            </I18nextProvider>
          </VersionControl>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
